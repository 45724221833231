 <template>
  <div class="expert">
    <div class="bot-box-details">
      <div class="bot-details w">
        <div class="bot-title">{{ xq.name }} <span class="zx" @click="zx">在线咨询</span></div>
        <div class="each-detail-list">
          <span class="lf-tab">
            <i class="el-icon-school"></i>
            <span class="tab-text">工作单位：{{ xq.company_name }}</span></span
          >
          <span class="lf-tab lf-tab-rg">
            <i class="el-icon-menu"></i>
            <span class="tab-text">研究领域：{{ xq.research }}</span></span
          >
        </div>
      </div>
    </div>
    <div class="wrapPolist w">
      <div class="describe">
        <h3 class="describe-name">专家描述</h3>
      </div>
      <!-- <div class="each-type">
        <h3 class="type-name">专家标签</h3>
        <p class="type-bottom">
          文案文案文案文案文案文案文案文案文文案文案文案文案文案文案文案文案文
        </p>
      </div> -->
      <!-- <div class="each-type">
        <h3 class="type-name">研究方向</h3>
        <p class="type-bottom">
          {{ xq.direction }}
        </p>
      </div> -->
      <div class="each-type">
        <h3 class="type-name">联系电话</h3>
        <p class="type-bottom" v-if="xq.phone">
          {{ xq.phone.substr(0, 3) + "****" + xq.phone.substr(7) }}
         
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">工作地址</h3>
        <p class="type-bottom">
          {{ xq.company_address }}
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">个人简介</h3>
        <p class="type-bottom">
          {{ xq.resume }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { ExpertXq } from "../../../common/js/api";
export default {
  name: "ExpertNei",
  data() {
    return {
      id: "",
      xq: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getExpertXq();
  },
  methods: {
    // 专家详情
    getExpertXq() {
      ExpertXq({
        id: this.id,
      })
        .then((res) => {
          console.log(res);
          this.xq = res.data;
          this.xq.research = this.xq.research.name;
        })
        .catch(() => {});
    },
    zx() {
       this.$router.push({
                  path: "/chat",
                });
    }
  },
};
</script>

<style lang="less" scoped>
.expert {
  background-color: #f8f9fa;
  padding-bottom: 25px;
  margin-top: 136px;
  .bot-box-details {
    background-color: #f0f8ff;
    font-size: 14px;
    color: #101010;
    .bot-details {
      padding: 69px 0 50px;
      margin: auto;
      min-height: 178px;
      .bot-title {
        font-size: 18px;
        max-width: 910px;
        font-weight: bolder;
        text-align: justify;
      }
      .zx {
        display: inline-block;
        background-color: #3892eb;
        padding: 3px 5px;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
        margin-left: 5px;
      }
      .each-detail-list {
        margin-top: 25px;
        i {
          color: #3892eb;
          margin-right: 8px;
        }
        .lf-tab-rg {
          margin-left: 40px;
        }
      }
    }
  }

  .wrapPolist {
    margin-top: 20px;
    background-color: #fff;
    padding: 50px 23px 50px;
    border-radius: 10px;
    .describe {
      margin-bottom: 37px;

      .describe-name {
        font-size: 20px;
        font-weight: 700;
        display: inline-block;
        background: #fff;
        color: #3892eb;
        position: relative;
        font-weight: 700;
        padding-right: 10px;
      }
    }

    .describe:before {
      content: "";
      display: block;
      border-top: 1px dotted #3892eb;
      position: relative;
      top: 0.7rem; /*调节线高*/
      left: 0;
    }
    .each-type {
      padding: 22px 0 10px;
      font-size: 14px;
      color: #00050a;
      .type-name {
        color: #3892eb;
        font-size: 18px;
        margin-bottom: 16px;
        border: 1px solid #3892eb;
        width: 102px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border-radius: 5px;
      }
      .type-bottom {
        color: rgba(105, 105, 105, 100);
        font-size: 14px;
        font-family: SourceHanSansSC-regular;
        padding-left: 14px;
        padding-bottom: 30px;
        line-height: 1.5;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	.expert{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.expert .bot-box-details .bot-details .bot-title{
		font-size: 2rem;
	}
	.expert .bot-box-details .bot-details .zx{
		font-size: 1.6rem;
		padding: 0.3rem 0.6rem;
	}
	.expert .bot-box-details .bot-details .each-detail-list i{
		font-size: 1.6rem;
	}
	.expert .bot-box-details{
		font-size: 1.5rem;
	}
	.expert .wrapPolist .describe .describe-name{
		font-size: 2rem;
	}
	.expert .wrapPolist .each-type .type-name{
		width: 8rem;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.6rem;
	}
	.expert .wrapPolist .each-type .type-bottom{
		font-size: 1.6rem;
		line-height: 2.5rem;
	}
}
</style>