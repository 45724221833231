<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 专家详情 -->
    <ExpertNei></ExpertNei>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import ExpertNei from "./base/ExpertNei";
export default {
  name: "Expert",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
      //console.log(item);
      this.ConList = item;
    },
  },
  components: {
    ConTab,
    ExpertNei,
  },
};
</script>

<style scoped>
</style>
